<template>
    <section class="why-stichting-550">
        <div class="row">
            <div class="about-col">
                <h1>Waarom Stichting 550</h1>
                <p>Veel leerlingen in achterstandswijken hebben te maken met een onderwijsachterstand, gedragsproblemen of moeilijkheden thuis. Scholen herkennen dat, maar schieten helaas tekort om dit probleem volledig het hoofd te bieden. Er is niet genoeg geld en aandacht voor basisscholen/middelbare scholen in achterstandswijken. Vaak moeten docenten onbetaald overwerken en zijn de leslokalen overvol. De kinderen lopen hierdoor een steeds grotere achterstand op. De scholen hebben reeds aan de bel getrokken. Het probleem stapelt zich op en een hele generatie dreigt verloren te gaan. Vanwege deze reden is Stichting550 opgericht. Wij zien het als onze verantwoordelijkheid om onze jeugd te helpen en zijn van mening dat deze kinderen niet aan hun lot mogen worden overgelaten. We moeten er voor onze kinderen zijn voordat het te laat is. Samen kunnen we het verschil maken!</p>
                <!-- <a href="" class="hero-btn red-btn">Steun ons</a> -->
            </div>
            <div class="about-col image-col-right">
                <img src="../assets/images/kinderen-met-vingers.jpeg" id="kids-fingers-img">
                <!-- <i class="fa-solid fa-question"></i> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
name: "WhyStichting550Section"
}
</script>

<style scoped>
.why-stichting-550 {
    width: 80%;
    margin: auto;
    padding: 20px 0 50px;
}
.about-col {
    flex-basis: 48%;
    padding: 30px 2px;
}
.row .image-col-right{
  display: flex;
  justify-content: center;
}
.about-col img {
    width: 100%;
    max-width: 600px;
}
.about-col h1 {
    padding-top: 0;
}
.about-col p {
    padding: 15px 0 0px;
}
#kids-fingers-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

@media(max-width: 978px) {
    .why-stichting-550 {
        padding: 0 0 41px;       /* t lr b */
    }
    .about-col {
        flex-basis: 48%;
        padding: 10px 2px;
    }
    .row {
        flex-direction: column;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    h3 {
        font-size: 16px;
    }

    .about-col img {
        max-width: 100%;
    }

    .about-col p {
        padding: 7px 0px 0px;
    }

    #kids-fingers-img {
        height: 200px;
    }

}
</style>