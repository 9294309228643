<template>
    <!-- Become Volunteer -->
    <section class="become-volunteer hidden">
        <h1>Aanmelden</h1>
        <p>Meld je aan als docent en kom ook meehelpen</p>
        <div class="row">
            <div class="become-volunteer-col">
                <h3>Wie wij zoeken</h3>
                <div class="bullet-points-container">
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Je draagt graag je steentje bij aan de maatschappij</span>
                    </div>
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Je bent actief en vindt het leuk om in teamverband te werken</span>
                    </div>   
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Je werkt graag met kinderen en jongeren</span>
                    </div>  
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Je bent doorgaans beschikbaar op zondagmiddag</span>
                    </div>  
                </div>
 
            </div>
            <div class="become-volunteer-col">
                <h3>Wat wij bieden</h3>
                <div class="bullet-points-container">
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Ervaar een gezellige sfeer waar je nieuwe mensen kan ontmoeten en nieuwe vrienden kan maken!</span>
                    </div>
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Ontwikkel competenties waar je graag in wilt groeien</span>
                    </div>
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Volg gratis trainingen van gecertificeerde kinderpsychologen over didactiek</span>
                    </div>      
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Doe nieuwe ervaringen op met lesgeven en coaching die mooi op je CV staan</span>
                    </div>  
                </div>
            </div>
        </div>

        <div class="comment-box-container-volunteer" v-if="!showThankYouMessage">
            <div class="comment-box">
                <h3>Schrijf je nu in!</h3>
                <form ref="becomeVolunteerForm" @submit.prevent="sendEmail" method="POST" class="comment-form" id="become-volunteer-form">
                    <input type="text" name="voornaam" :placeholder="firstnamePlaceholder" id="firstname-input" :class="!firstNameValid ? 'error' : ''" v-model="firstnameInput" @blur="validateFirstName()">
                    <input type="text" name="achternaam" :placeholder="lastnamePlaceholder" id="lastname-input" :class="!lastNameValid ? 'error' : ''" v-model="lastnameInput" @blur="validateLastName()">
                    <div class="date-of-birth-container">
                        <DatePicker :placeholder="dateofbirthPlaceholder" @validate="validateDateOfBirth()" @remove-css-class="removeCssClass" :customClass="!emailValid ? 'error' : ''" />
                        <i @click="giveDateOfBirthInputFocus" class="fa-solid fa-calendar" id="calendar-icon"></i>
                    </div>
                    <div class="email-field-container">
                        <input type="email" name="email" :placeholder="emailPlaceholder" id="email-input" :class="!emailValid ? 'error' : ''" v-model="emailInput" @blur="validateEmail()">
                        <i @click="GiveEmailInputFocus()" class="fa-solid fa-envelope" id="email-field-icon"></i>
                    </div>

                    <input type="tel" name="telefoonnummer" :placeholder="phonePlaceholder" id="phone-input" :class="!phoneValid ? 'error' : ''" v-model="phoneInput" @blur="validatePhone()">

                    <input type="text" name="adres" :placeholder="addressPlaceholder" id="address-input" :class="!addressValid ? 'error' : ''" v-model="addressInput" @blur="validateAddress()">

                    <input type="text" name="postcode_woonplaats" :placeholder="zipcodeCityPlaceholder" id="zipcode-city-input" :class="!zipcodeCityValid ? 'error' : ''" v-model="zipcodeCityInput" @blur="validateZipcodeCity()">

                    <div class="radio-btns-branches-container">
                        <div class="field-title">
                            Kies een vestiging
                        </div>
                        <div class="radio-btns-branches">
                            <!-- <div class="option-container">
                                <input type="radio" id="branche-denhaag" name="vestiging" value="Den Haag" v-model="chosenBranche" @change="validateBranches()">
                                <label for="branche-denhaag">Den Haag</label><br>
                            </div> -->
                            <div class="option-container">
                                <input type="radio" id="branche-amsterdam" name="vestiging" value="Amsterdam" v-model="chosenBranche" @change="validateBranches()">
                                <label for="branche-amsterdam">Amsterdam</label><br>  
                            </div>
                            <div class="option-container">
                                <input type="radio" id="branche-rotterdam" name="vestiging" value="Rotterdam" v-model="chosenBranche" @change="validateBranches()">
                                <label for="branche-rotterdam">Rotterdam</label><br>  
                            </div>
                            <span id="branches-error" :style="[!brancheValid ? {'display': 'block'} : {'display': 'none'}]">Kies een vestiging</span>
                        </div>
                    </div>
                    <textarea rows="5" name="korte_motivatie" :placeholder="motivationPlaceholder" id="motivation-input" :class="!motivationValid ? 'error' : ''" v-model="motivationInput" @blur="validateMotivation()"></textarea>
                    <button type="submit" class="hero-btn red-btn">Inschrijven</button>

                </form>
            </div>
        </div>
        <div v-if="showThankYouMessage" class="thank-you-message-become-volunteer">
                    <h5 class="title">Bedankt voor je aanmelding</h5>
                    <div class="message">
                        <div class="greeting">Beste {{ firstnameInput }} {{ lastnameInput }},</div>
                        <div class="content"> Bedankt voor je aanmelding, binnen enkele ogenblikken ontvang je een bevestigingsmail. We zullen z.s.m contact met je opnemen.</div>
                        <div class="end-greeting">Met vriendelijke groet,</div>
                        <div>Team Stichting550</div>
                    </div>
            </div>
    </section>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import emailjs from '@emailjs/browser';

export default {
name: "BecomeVolunteer",
components: {
    DatePicker
},
data() {
    return {
        firstnameInput: '',
        firstnamePlaceholder: 'Voornaam',
        lastnameInput: '',
        lastnamePlaceholder: 'Achternaam',
        dateofbirthInput: '',
        dateofbirthPlaceholder: 'Geboortedatum',
        emailInput: '',
        emailPlaceholder: 'Email',
        phoneInput: '',
        phonePlaceholder: 'Telefoonnummer',
        addressInput: '',
        addressPlaceholder: 'Adres',
        zipcodeCityInput: '',
        zipcodeCityPlaceholder: 'Postcode en woonplaats',
        chosenBranche: '',
        motivationInput: '',
        motivationPlaceholder: 'Korte motivatie',
        branchesError: '',
        firstNameValid: true,
        lastNameValid: true,
        dateofbirthValid: true,
        emailValid: true,
        phoneValid: true,
        addressValid: true,
        zipcodeCityValid: true,
        brancheValid: true,
        motivationValid: true,
        showThankYouMessage: false
    }
  },
  methods: {
    validateFirstName(){
            if(this.firstnameInput == '') {
                this.firstNameValid= false;
                this.firstnamePlaceholder = 'Voornaam is verplicht'
            }
            else {
                this.firstNameValid = true;
            } 
        },
    validateLastName(){
            if(this.firstnameInput == '') {
                this.lastNameValid= false;
                this.lastnamePlaceholder = 'Achternaam is verplicht'
            }
            else {
                this.lastNameValid = true;
            } 
        },
    validateDateOfBirth(){
        const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
        this.dateofbirthInput = dateOfBirthInput.value;
        // todo: regex for date
        if(this.dateofbirthInput == '') {
            this.dateofbirthValid= false;
            this.dateofbirthPlaceholder = 'Geboortedatum is verplicht'
            dateOfBirthInput.style.setProperty('--placeholder-color', 'red')
        }
        else {
            this.dateofbirthValid = true;
        }

        setTimeout(() => this.removeCssClass(), 10);
        
        
    },
    validateEmail(){
        var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(this.emailInput == '') {
            this.emailValid= false;
            this.emailPlaceholder = 'Email is verplicht'
        }          
         else if(!this.emailInput.match(validRegex)) {
            this.emailValid= false;
            this.emailPlaceholder = this.emailInput + ' is geen geldig email';
            this.emailInput = "";
            }
        else {
            this.emailValid = true;
        } 
    },
    validatePhone(){
        var validRegex =  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        if(this.phoneInput == '') {
            this.phoneValid= false;
            this.phonePlaceholder = 'Telefoonnummer is verplicht'
        }          
         else if(!this.phoneInput.match(validRegex)) {
                this.phoneValid= false;
                this.phonePlaceholder = this.phoneInput + ' is geen geldig telefoonnummer';
                this.phoneInput = "";
            }
        else {
            this.phoneValid = true;
        } 
    },
    validateAddress(){
            if(this.addressInput == '') {
                this.addressValid= false;
                this.addressPlaceholder = 'Adres is verplicht'
            }
            else {
                this.addressValid = true;
            } 
        },
    validateZipcodeCity(){
            if(this.zipcodeCityInput == '') {
                this.zipcodeCityValid= false;
                this.zipcodeCityPlaceholder = 'Postcode en woonplaats is verplicht'
            }
            else {
                this.zipcodeCityValid = true;
            } 
        },
    validateBranches(){
            if(!this.chosenBranche ) {
                this.brancheValid = false;
            }
            else {
                this.brancheValid = true;
            } 
        },
    validateMotivation(){
            if(this.motivationInput == '') {
                this.motivationValid= false;
                this.motivationPlaceholder = 'Motivatie is verplicht'
            }
            else {
                this.motivationValid = true;
            } 
        },
        sendEmail() {
            this.validateFirstName();
            this.validateLastName();
            this.validateDateOfBirth();
            this.validateEmail();
            this.validatePhone();
            this.validateAddress();
            this.validateZipcodeCity();
            this.validateBranches();
            this.validateMotivation();

            if(this.firstNameValid && this.lastNameValid && this.dateofbirthValid && this.emailValid &&
               this.phoneValid && this.addressValid && this.zipcodeCityValid && this.brancheValid && this.motivationValid){     
                    emailjs.sendForm('become_volunteer_service', 'become_volunteer_form', this.$refs.becomeVolunteerForm, 'G4Q6HP5vZt0Xg3Uu1')
                    .then((result) => {
                        console.log('SUCCESS!', result.text);
                        this.showThankYouMessage = true
                    }, (error) => {
                        console.log('FAILED...', error.text);
                        this.showThankYouMessage = false
                    });
            } 
 
    },
        giveDateOfBirthInputFocus() {
            const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
            dateOfBirthInput.click();
        },
        GiveEmailInputFocus() {
            const emailInput = document.getElementById("email-input");
            emailInput.focus();
        },
        removeCssClass() {
            const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
            
            if(dateOfBirthInput != null)
            {
                dateOfBirthInput.classList.remove('dp__input');
                dateOfBirthInput.classList.add('dp__input_new');

                if(this.dateofbirthValid) {
                    dateOfBirthInput.style.setProperty('--placeholder-color', '#6f6f6f')
                }
            }

        }

  },
mounted(){
    this.$store.commit('updateHeaderTitle', 'Word vrijwilliger')
    this.$store.commit('updateHeaderSubtitle', 'Stichting 550’s drijvende kracht is ons team van gepassioneerde vrijwilligers, die al meer dan 10 jaar lang wekelijks gratis bijlessen verzorgen. Meld je hieronder aan om mee te doen aan dit mooie avontuur.')
    this.$store.commit('updateHeaderBtn', false)
    this.removeCssClass();

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Word vrijwilliger - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Stichting 550’s drijvende kracht is ons team van gepassioneerde vrijwilligers, die al meer dan 10 jaar lang wekelijks gratis bijlessen verzorgen. Meld je hieronder aan om mee te doen aan dit mooie avontuur.");


    }
}
</script>

<style>
.become-volunteer {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 60px;        /* t lr b */
}

.become-volunteer-col {
    flex-basis: 40%;
    background: #FFF3F3;
    border-radius: 10px;
    /* margin-top: 5px; */
    box-sizing: border-box;
    transition: 0.5s;
    /* cursor: pointer; */
    padding: 15px 20px;
    text-align: left;
    min-width: 35vw;
}
.become-volunteer-col .bullet-points-container {
    width: fit-content;
    margin: auto;
}
.become-volunteer-col .bullet-point {
    display: flex;
    column-gap: 2px;
    margin-bottom: 10px;
}

.become-volunteer-col .fa-check {
    padding-right: 10px;
}

.become-volunteer-col h3{
    color: #000000;
    margin-bottom: 15px;
}



.comment-box-container-volunteer {
    /* background-color: pink; */
    margin: 80px auto;
}
.comment-box-container-volunteer h3 {
    /* background-color: red; */
    font-size: 28px;
}
.comment-box {
    border: 1px solid #ccc;
    padding: 10px 20px;
    max-width: 500px;
    /* width: 50%; */
    margin: auto;
}
.comment-box h3 {
    font-size: 20px;
    margin-bottom: 20px;
}
.comment-form input, .comment-form textarea {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: #F0F0F0;
    resize: none;
}
.comment-form button {
    margin: 10px auto;
    display: block;
}
.error::-webkit-input-placeholder {
    color: red;
}
.date-of-birth-container {
    position: relative;
}
#calendar-icon{
    color: #606060;
    position: absolute;
    bottom: 29px;
    right: 15px;
    cursor: pointer;
}
.email-field-container {
    position: relative;
}
#email-field-icon{
    color: #606060;
    position: absolute;
    bottom: 25px;
    right: 15px;
}
/* radio buttons */

.radio-btns-branches-container {
    margin-top: 8px;
    padding-left: 10px;
}
.radio-btns-socialmedia-container {
    /* background-color: pink; */
    margin-top:14px;
}
.radio-btns-branches-container .field-title {
    text-align: left;
    font-size: 14px;
    margin-bottom: 3px;
    max-width: 400px;
}
.radio-btns-branches {
    display: flex;
    column-gap: 18px;
    flex-wrap: wrap;
}

.option-container{
    /* background-color: red; */
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
}
.option-container input[type='radio'] {
    width: 12px;
    height: 12px;
    background-color: lightblue;
}
.option-container input[type='radio']:hover{
    cursor: pointer;
}
.option-container label {
    padding-left: 10px;
    font-size: 13px;
    cursor: pointer;
}
.radio-btns-branches #branches-error {
    font-size: 13.5px;
    color: red;
    display: none;
    align-self: center; 
    margin-left: auto;
}



.hero-btn {
        display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 12px 34px;
        font-size: 16px;
        background: transparent;
        position: relative;
        cursor: pointer;
    }
.hero-btn:hover {
    border: 1px solid #F44336;
    background: #F44336;
    transition: 1s;
}
.red-btn {
    border: 1px solid #F44336;
    background: transparent;
    color: #F44336;
    margin-top: 30px;
}
.red-btn:hover {
    color: #FFF;
}
.dp-custom-input {
    /* height: 300px; */
}
.dp__input_new {
    height: 44px;
}

.thank-you-message-become-volunteer {
    text-align: left;
    display: block;
    margin: auto;
    width: fit-content;
    margin: 80px auto;
}
.thank-you-message-become-volunteer .title {
    font-size: 24px;
    padding-bottom: 15px;
    color: #555;
    font-weight: 400;
}
.thank-you-message-become-volunteer .message {
    color: #606060;
}
.thank-you-message-become-volunteer .message .greeting{
    padding-bottom:15px;
}
.thank-you-message-become-volunteer .message .content{
    padding-bottom:15px;
}
.thank-you-message-become-volunteer .message .end-greeting{
    padding-bottom:5px;
}

@media(max-width: 978px){
    .become-volunteer {
        padding: 40px 0 60px;        /* t lr b */
    }
    .become-volunteer-col {
        margin: -10px 0px 50px;
        min-width: auto;
        
    }
    .become-volunteer-col .bullet-points-container{
        font-size: 14px;
        width: unset;
    }
    .sub-header h1 {
        font-size: 24px;
    }
    .radio-btns-branches {
        flex-direction: column;
    }
    .radio-btns-branches #branches-error {
        font-size: 13px;
        color: red;
        margin-left: 22px;
        align-self: start;
    }
    .radio-btns-branches #branches-error {
        margin-top: 2px;
        margin-bottom: 5px;
    }


    .comment-box-container-volunteer {
        margin: -8px auto 15px;
    }
    .comment-box h3 {
        font-size: 18px;
    }
    .thank-you-message-become-volunteer {
        margin: 45px auto;
    }  
    .thank-you-message-become-volunteer .title {
        font-size: 20px;
    } 
    
}
</style>