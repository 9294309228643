<template>
      <section class="individual-member hidden">
        <div class="row">
                <div class="individual-col">
                        <img :src="require(`../assets/images/${activeMember.image}`)">
                        <h3>{{ activeMember.name }}</h3>
                        <span>{{ activeMember.branche }}</span>
                </div>
                <div class="individual-col-text">
                    <div class="about-yourself" v-html="activeMember.text">
                    </div>
                    <OrangeWhiteBtn :text="'Terug'"/>
                </div>
        </div>
    </section>
</template>

<script>
import OrangeWhiteBtn from "@/components/OrangeWhiteBtn.vue";
export default {
    name: "TeamMember",
    components: {
        OrangeWhiteBtn
    },
    computed: {
        memberId(){
            return this.$route.params.memberid
        },
        activeMember(){
            return this.$store.state.members.find(member => member.id == this.memberId)
        }
    },
    mounted(){
        this.$store.commit('updateHeaderTitle', this.activeMember.name)
        this.$store.commit('updateHeaderSubtitle', this.activeMember.motivation)
        this.$store.commit('updateHeaderBtn', false)

        const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = this.activeMember.name + " - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", this.activeMember.motivation);

    },
    async beforeRouteUpdate(to) {
        // react to route changes...
        this.$store.commit('updateHeaderTitle', this.$store.state.members.find(member => member.id == to.params.memberid).name)
        this.$store.commit('updateHeaderSubtitle', this.$store.state.members.find(member => member.id == to.params.memberid).motivation)
  },
}
</script>

<style>
/* Individual member page */
.individual-member {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 160px;        /* t lr b */
    /* background-color: red; */
}
.individual-col {
    flex-basis: 31%;
    background: #FFF3F3;
    border-radius: 10px;
    /* margin-top: 5px; */
    box-sizing: border-box;
    transition: 0.5s;
}
.individual-col span{
    color: #606060;
    font-size: 14px;
}
.individual-col img {
    width: 100%;
    max-width: 375px;
}
.individual-member .row .individual-col  {
    display: block;
    padding: 43px 40px 35px;
    text-decoration: none;
}
.individual-member .row {
    margin-top: 0px;
    /* background-color: red; */
}
.individual-col-text {
    /* background-color: pink; */
    flex-basis: 61%;
}
.individual-col-text .about-yourself {
    text-align: left;
    margin-bottom: 10px;
    white-space: pre-wrap;
}
.about-yourself .new-line {
    display: block;
    padding-top: 13px;
}
@media(max-width: 978px) {
    .individual-member {
        padding: 40px 0 90px;        /* t lr b */
    }
    .individual-col {
        margin: auto;
    }
    .individual-col-text {
        margin-top: 40px;
    }
}

</style>