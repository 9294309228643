<template>
  <div class="page-not-found">
    Deze pagina kon niet worden gevonden
  </div>
</template>

<script>
export default {
name: 'NotFound',
mounted(){
      document.title = "Niet gevonden - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Deze pagina kon niet worden gevonden");

      
    }
}
</script>

<style>
.page-not-found {
  width: 80%;
  margin: auto;
  padding: 100px 0;  /* center vertically */
  text-align: center; /* center horizontally */
  font-size: 36px;

}

@media(max-width: 978px) {
    .page-not-found {
      font-size: 20px;
  }
}

</style>