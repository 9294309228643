<template>
    <!-- Become Volunteer -->
    <section class="periodic-donation hidden">
        <div v-if="!showThankYouMessage">
            <h1>Periodieke donatie</h1>
            <p>Vul het onderstaand formulier in om via automatische incasso periodiek te doneren</p>
        </div>
        <div class="comment-box-container" v-if="!showThankYouMessage">
            <div class="comment-box">
                <h3>Vul uw gegevens in</h3>
                <form ref="periodicDonationForm" @submit.prevent="sendEmail" method="POST" class="comment-form" id="become-volunteer-form">
                    <input type="text" name="voornaam" :placeholder="firstnamePlaceholder" id="firstname-input" :class="!firstNameValid ? 'error' : ''" v-model="firstnameInput" @blur="validateFirstName()">
                    <input type="text" name="achternaam" :placeholder="lastnamePlaceholder" id="lastname-input" :class="!lastNameValid ? 'error' : ''" v-model="lastnameInput" @blur="validateLastName()">                   
                    <div class="date-of-birth-container">
                        <DatePicker :placeholder="dateofbirthPlaceholder" @validate="validateDateOfBirth()" @remove-css-class="removeCssClass" :customClass="!emailValid ? 'error' : ''" />
                        <i @click="giveDateOfBirthInputFocus" class="fa-solid fa-calendar" id="calendar-icon"></i>
                    </div>
                    <div class="email-field-container">
                        <input type="email" name="email" :placeholder="emailPlaceholder" id="email-input" :class="!emailValid ? 'error' : ''" v-model="emailInput" @blur="validateEmail()">
                        <i @click="GiveEmailInputFocus()" class="fa-solid fa-envelope" id="email-field-icon"></i>
                    </div>

                    <input type="tel" name="telefoonnummer" :placeholder="phonePlaceholder" id="phone-input" :class="!phoneValid ? 'error' : ''" v-model="phoneInput" @blur="validatePhone()">

                    <input type="text" name="adres" :placeholder="addressPlaceholder" id="address-input" :class="!addressValid ? 'error' : ''" v-model="addressInput" @blur="validateAddress()">

                    <input type="text" name="postcode_woonplaats" :placeholder="zipcodeCityPlaceholder" id="zipcode-city-input" :class="!zipcodeCityValid ? 'error' : ''" v-model="zipcodeCityInput" @blur="validateZipcodeCity()">

                    <input type="text" name="iban_nummer" :placeholder="ibanNumberPlaceholder" id="iban-number-input" :class="!ibanNumberValid ? 'error' : ''" v-model="ibanNumberInput" @blur="validateIbanNumber()">

                    <input type="text" name="naam_rekeninghouder" :placeholder="nameAccountHolderPlaceholder" id="name-account-holder" :class="!nameAccountHolderValid ? 'error' : ''" v-model="nameAccountHolderInput" @blur="validateNameAccountHolder()">
                   
                    <div class="radio-btns-amount-container">
                        <div class="field-title">
                            Bedrag
                        </div>
                        <div class="radio-btns-amount">
                            <div class="option-container">
                                    <input type="radio" id="amount-5" name="bedrag" value="€5" v-model="chosenAmount" @change="validateAmount()">
                                    <label for="amount-5">€5</label><br>
                            </div>
                            <div class="option-container">
                                <input type="radio" id="amount-10" name="bedrag" value="€10" v-model="chosenAmount" @change="validateAmount()">
                                <label for="amount-10">€10</label><br>  
                            </div>
                            <div class="option-container">
                                <input type="radio" id="amount-15" name="bedrag" value="€15" v-model="chosenAmount" @change="validateAmount()">
                                <label for="amount-15">€15</label><br>  
                            </div>
                            <div class="option-container">
                                <input ref="otherwiseOption" type="radio" id="otherwise-amount" name="bedrag" :value="'€'+chosenAmountOtherwise" @change="validateAmount()" @focus="makeOtherwiseInputActive()">
                                <label for="otherwise-amount">Anders, namelijk: &nbsp;€<input ref="otherwiseInput" type="text" v-model="chosenAmountOtherwise" id="otherwise-amount-input" @focus="makeOtherwiseActive()"> </label><br>  
                            </div>
                            <span id="amount-error" :style="[!amountValid ? {'display': 'block'} : {'display': 'none'}]">Kies bedrag</span>
                        </div>
                    </div>

                    <div class="radio-btns-term-container">
                        <div class="field-title">
                            Betaaltermijn
                        </div>
                        <div class="radio-btns-term">
                            <div class="option-container">
                                <input type="radio" id="term-monthly" name="betaaltermijn" value="maandelijks" v-model="chosenTerm" @change="validateTerm()">
                                <label for="term-monthly">Maandelijks</label><br>
                            </div>
                            <div class="option-container">
                                <input type="radio" id="term-every-quarter" name="betaaltermijn" value="per kwartaal" v-model="chosenTerm" @change="validateTerm()">
                                <label for="term-every-quarter">Per kwartaal</label><br>  
                            </div>
                            <span id="term-error" :style="[!termValid ? {'display': 'block'} : {'display': 'none'}]">Kies betaaltermijn</span>
                        </div>
                    </div>

                    <textarea rows="5" name="opmerkingen_of_vragen" :placeholder="commentsOrQuestionsPlaceholder" id="comments-or-questions-input" v-model="commentsOrQuestionsInput"></textarea>
                    <button type="submit" class="hero-btn red-btn">Verzenden</button>

                </form>
            </div>
        </div>
        <div v-if="showThankYouMessage" class="thank-you-message-periodic-donation">
            <h5 class="title">Bedankt voor uw aanmelding</h5>
            <div class="message">
                <div class="greeting">Beste {{ firstnameInput }} {{ lastnameInput }},</div>
                <div class="content"> Erg bedankt voor uw aanmelding als vast donateur. Binnen enkele ogenblikken ontvangt u een bevestigingsmail.</div>
                <div class="end-greeting">Met vriendelijke groet,</div>
                <div>Team Stichting550</div>
            </div>
        </div>
    </section>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import emailjs from '@emailjs/browser';

export default {
name: "PeriodicDonation",
components: {
    DatePicker
},
data() {
    return {
        firstnameInput: '',
        firstnamePlaceholder: 'Voornaam',
        lastnameInput: '',
        lastnamePlaceholder: 'Achternaam',
        dateofbirthInput: '',
        dateofbirthPlaceholder: 'Geboortedatum',
        emailInput: '',
        emailPlaceholder: 'Email',
        phoneInput: '',
        phonePlaceholder: 'Telefoonnummer',
        addressInput: '',
        addressPlaceholder: 'Adres',
        zipcodeCityInput: '',
        zipcodeCityPlaceholder: 'Postcode en woonplaats',
        ibanNumberInput: '',
        ibanNumberPlaceholder: 'IBAN nummer',
        nameAccountHolderInput: '',
        nameAccountHolderPlaceholder: 'Naam rekeninghouder',
        chosenAmount: '',
        chosenAmountOtherwise: '',
        chosenTerm: '',
        commentsOrQuestionsInput: '',
        commentsOrQuestionsPlaceholder: 'Opmerkingen of vragen',
        branchesError: '',
        firstNameValid: true,
        lastNameValid: true,
        dateofbirthValid: true,
        emailValid: true,
        phoneValid: true,
        addressValid: true,
        zipcodeCityValid: true,
        ibanNumberValid: true,
        bankValid: true,
        nameAccountHolderValid: true,
        amountValid: true,
        termValid: true,
        showThankYouMessage: false
    }
  },
  methods: {
    validateFirstName(){
            if(this.firstnameInput == '') {
                this.firstNameValid= false;
                this.firstnamePlaceholder = 'Voornaam is verplicht'
            }
            else {
                this.firstNameValid = true;
            } 
        },
    validateLastName(){
            if(this.lastnameInput == '') {
                this.lastNameValid= false;
                this.lastnamePlaceholder = 'Achternaam is verplicht'
            }
            else {
                this.lastNameValid = true;
            } 
        },
    validateDateOfBirth(){
        const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
        this.dateofbirthInput = dateOfBirthInput.value;
        // todo: regex for date
        if(this.dateofbirthInput == '') {
            this.dateofbirthValid= false;
            this.dateofbirthPlaceholder = 'Geboortedatum is verplicht'
            dateOfBirthInput.style.setProperty('--placeholder-color', 'red')
        }
        else {
            this.dateofbirthValid = true;
        }

        setTimeout(() => this.removeCssClass(), 10);
        
        
    },
    validateEmail(){
        var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(this.emailInput == '') {
            this.emailValid= false;
            this.emailPlaceholder = 'Email is verplicht'
        }          
         else if(!this.emailInput.match(validRegex)) {
            this.emailValid= false;
            this.emailPlaceholder = this.emailInput + ' is geen geldig email';
            this.emailInput = "";
            }
        else {
            this.emailValid = true;
        } 
    },
    validatePhone(){
        var validRegex =  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        if(this.phoneInput == '') {
            this.phoneValid= false;
            this.phonePlaceholder = 'Telefoonnummer is verplicht'
        }          
         else if(!this.phoneInput.match(validRegex)) {
                this.phoneValid= false;
                this.phonePlaceholder = this.phoneInput + ' is geen geldig telefoonnummer';
                this.phoneInput = "";
            }
        else {
            this.phoneValid = true;
        } 
    },
    validateAddress(){
            if(this.addressInput == '') {
                this.addressValid= false;
                this.addressPlaceholder = 'Adres is verplicht'
            }
            else {
                this.addressValid = true;
            } 
        },
    validateZipcodeCity(){
            if(this.zipcodeCityInput == '') {
                this.zipcodeCityValid= false;
                this.zipcodeCityPlaceholder = 'Postcode en woonplaats is verplicht'
            }
            else {
                this.zipcodeCityValid = true;
            } 
        },
    validateIbanNumber(){
        if(this.ibanNumberInput == '') {
                this.ibanNumberValid = false;
                this.ibanNumberPlaceholder = 'IBAN nummer is verplicht'
            }
            else {
                this.ibanNumberValid = true;
            } 
    },
    validateNameAccountHolder(){
        if(this.nameAccountHolderInput == '') {
                this.nameAccountHolderValid = false;
                this.nameAccountHolderPlaceholder = 'Naam rekeninghouder is verplicht'
            }
            else {
                this.nameAccountHolderValid = true;
            } 
    },
    validateAmount(){
            if(!this.chosenAmount && !this.chosenAmountOtherwise) {
                this.amountValid = false;
            }
            else if(this.chosenAmount || this.chosenAmountOtherwise) {
                this.amountValid = true;
            }

            if(!this.$refs.otherwiseOption.checked){
                this.chosenAmountOtherwise= ''
            }

            if(this.$refs.otherwiseOption.checked){
                this.chosenAmount= ''
                this.amountValid = true; // extra check onblur event
            }
        },
        validateAmountOtherwise() {
            if(this.$refs.otherwiseOption.checked && this.chosenAmountOtherwise == ''){
                this.amountValid = false; 
            }
        },
        validateTerm() {
            if(!this.chosenTerm) {
                this.termValid = false;
            } 
            else {
                this.termValid = true;
            }
        },
        sendEmail() {
            this.validateFirstName();
            this.validateLastName();
            this.validateDateOfBirth();
            this.validateEmail();
            this.validatePhone();
            this.validateAddress();
            this.validateZipcodeCity();
            this.validateIbanNumber();
            this.validateNameAccountHolder();
            this.validateAmount();
            this.validateAmountOtherwise();
            this.validateTerm();

            if(this.firstNameValid && this.lastNameValid && this.dateofbirthValid && this.emailValid &&
               this.phoneValid && this.addressValid && this.zipcodeCityValid && this.ibanNumberValid && 
               this.nameAccountHolderValid && this.amountValid && this.termValid) {
                    emailjs.sendForm('periodic_donation', 'periodic_donation_form', this.$refs.periodicDonationForm, 'XGrvKn-Sa97OXwqCY')
                    .then((result) => {
                        console.log('SUCCESS!', result.text);
                        this.showThankYouMessage = true
                        this.scrollToTop()
                    }, (error) => {
                        console.log('FAILED...', error.text);
                        this.showThankYouMessage = false
                    });
            } 
 
    },
        giveDateOfBirthInputFocus() {
            const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
            dateOfBirthInput.click();
        },
        GiveEmailInputFocus() {
            const emailInput = document.getElementById("email-input");
            emailInput.focus();
        },
        removeCssClass() {
            const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
        
            if(dateOfBirthInput != null)
            {
                dateOfBirthInput.classList.remove('dp__input');
                dateOfBirthInput.classList.add('dp__input_new');

                if(this.dateofbirthValid) {
                    dateOfBirthInput.style.setProperty('--placeholder-color', '#6f6f6f')
                }
            }
        },
        makeOtherwiseActive(){
            this.$refs.otherwiseOption.click();
        },
        makeOtherwiseInputActive(){
            this.$refs.otherwiseInput.focus();
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }

  },
mounted(){
    this.$store.commit('updateHeaderTitle', 'Steun ons')
    this.$store.commit('updateHeaderSubtitle', 'Uw bijdrage gaat 100% naar de kinderen aangezien alle begeleiders en bestuursleden zich geheel vrijwillig inzetten voor de stichting.')
    this.$store.commit('updateHeaderBtn', false)
    this.removeCssClass();

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Steun ons - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Uw bijdrage gaat 100% naar de kinderen aangezien alle begeleiders en bestuursleden zich geheel vrijwillig inzetten voor de stichting.");
  }
}
</script>

<style>
.periodic-donation {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 60px;        /* t lr b */
    /* background-color: red; */
}
.blog-left {
    flex-basis: 42%;
}
.blog-left h3 {
    background-color: #00bcfb;
    background-image:linear-gradient(rgba(4,9,30,0.2), rgba(4,9,30,0.2));
    /* background: #F44336; */
    color: #FFF;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
}
.blog-left div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #555;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
}
.blog-left div:nth-of-type(odd) {
    background-color: #F0F8FF;
}
.blog-left div span:nth-child(2){
    padding-left:17px;
    color: #002D62;
}


.blog-right {
    flex-basis: 42%;
}
.blog-right h3 {
    background-color: #00bcfb;
    background-image:linear-gradient(rgba(4,9,30,0.2), rgba(4,9,30,0.2));
    /* background: #F44336; */
    color: #FFF;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
}
.blog-right div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #555;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
}
.blog-right div:nth-of-type(odd) {
    background-color: #F0F8FF;
}
.blog-right div span:nth-child(2){
    padding-left:17px;
    color: #002D62;
}

.comment-box-container {
    /* background-color: pink; */
    margin: 40px auto;
}
.comment-box-container h3 {
    /* background-color: red; */
    font-size: 28px;
}
.comment-box {
    border: 1px solid #ccc;
    padding: 10px 20px;
    max-width: 500px;
    /* width: 50%; */
    margin: auto;
}
.comment-box h3 {
    font-size: 20px;
    margin-bottom: 20px;
}
.comment-form input, .comment-form textarea {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: #F0F0F0;
    resize: none;
}
.comment-form button {
    margin: 10px auto;
    display: block;
}
.error::-webkit-input-placeholder {
    color: red;
}
.date-of-birth-container {
    position: relative;
}
#calendar-icon{
    color: #606060;
    position: absolute;
    bottom: 29px;
    right: 15px;
    cursor: pointer;
}
.email-field-container {
    position: relative;
}
#email-field-icon{
    color: #606060;
    position: absolute;
    bottom: 25px;
    right: 15px;
}
/* radio buttons amount */
.radio-btns-amount-container {
    margin-top: 6px;
    padding-left: 10px;
}
.radio-btns-amount-container .field-title {
    text-align: left;
    font-size: 14px;
    margin-bottom: -7px;
}
.radio-btns-amount {
    /* background-color: pink; */
    display: flex;
    column-gap: 18px;
    flex-wrap: wrap;
}
.radio-btns-amount #amount-error, .radio-btns-term #term-error{
    font-size: 13.5px;
    color: red;
    display: none;
    align-self: center; 
    margin-left: auto;
}
.option-container{
    /* background-color: red; */
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
}
.option-container input[type='radio'] {
    width: 12px;
    height: 12px;
    background-color: lightblue;
}
.option-container input[type='radio']:hover{
    cursor: pointer;
}
.option-container label {
    padding-left: 10px;
    font-size: 13px;
    cursor: pointer;
}
#otherwise-amount-input {
    /* background-color: blue; */
    width: 50px;
    height: 30px;
    margin-left: 5px;
}

/* radio buttons term */
.radio-btns-term-container {
    margin-top: 8px;
    padding-left: 10px;
}
.radio-btns-term-container .field-title {
    text-align: left;
    font-size: 14px;
    margin-bottom: 0px;
}
.radio-btns-term {
    display: flex;
    column-gap: 18px;
    flex-wrap: wrap;
}


.hero-btn {
        display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 12px 34px;
        font-size: 16px;
        background: transparent;
        position: relative;
        cursor: pointer;
    }
.hero-btn:hover {
    border: 1px solid #F44336;
    background: #F44336;
    transition: 1s;
}
.red-btn {
    border: 1px solid #F44336;
    background: transparent;
    color: #F44336;
    margin-top: 30px;
}
.red-btn:hover {
    color: #FFF;
}
.dp-custom-input {
    /* height: 300px; */
}
.dp__input_new {
    height: 44px;
}

.thank-you-message-periodic-donation {
    text-align: left;
    display: block;
    margin: auto;
    width: fit-content;
    margin: 30px auto;
}
.thank-you-message-periodic-donation .title {
    font-size: 24px;
    padding-bottom: 15px;
    color: #555;
    font-weight: 400;
}
.thank-you-message-periodic-donation .message {
    color: #606060;
}
.thank-you-message-periodic-donation .message .greeting{
    padding-bottom:15px;
}
.thank-you-message-periodic-donation .message .content{
    padding-bottom:15px;
}
.thank-you-message-periodic-donation .message .end-greeting{
    padding-bottom:5px;
}


@media(max-width: 978px){
    .periodic-donation {
        padding: 40px 0 60px;        /* t lr b */
    }
    .sub-header h1 {
        font-size: 24px;
    }
    .radio-btns-amount-container .field-title {
        margin-bottom: 5px;
    }
    .radio-btns-amount {
        flex-direction: column;
    }
    .radio-btns-amount #amount-error, .radio-btns-term #term-error{
        font-size: 13px;
        color: red;
        margin-left: 22px;
        align-self: start;
    }
    .radio-btns-amount #amount-error {
        margin-bottom: 10px;
    }
    .radio-btns-term #term-error {
        margin-top: 6px;
        margin-bottom: 5px;
    }
    .radio-btns-term-container .field-title {
        margin-top:12px;
        margin-bottom: 5px;
    }
    .radio-btns-term {
        flex-direction: column;
    }

    .option-container:nth-child(4) {
        margin-top: -12px;
    }

    .volunteer-info-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 20px;
    }
    .comment-box-container {
        margin: 25px auto;
    }
    .comment-box h3 {
        font-size: 18px;
    }
    .thank-you-message-periodic-donation .title {
        font-size: 20px;
    } 
    
    
}
</style>