<template>
    <section class="sova">
          <div class="row">
            <div class="about-col">
                <h1>SoVA lessen</h1>
                <p>
                    Een SoVA-training(sociale-vaardigheidstraining) is bedoeld voor kinderen en jongeren die sociaal minder vaardig zijn. Doordat ze op sociaal vlak tekort schieten kunnen ze problemen ervaren in de dagelijkse omgang met leeftijdsgenoten en anderen. Om die reden organiseert Stichting550 elk schooljaar een aantal SoVA-trainingen zodat de leerlingen zich op dit gebied kunnen ontwikkelen. 
                </p>
                <p>
                    Sociale vaardigheden helpen bij het opbouwen en behouden van sociale contacten, en zorgen ervoor dat iemand wordt geaccepteerd door anderen. Een SoVA-training heb je in verschillende vormen. Jezelf voorstellen, een gesprekje beginnen, iets vragen of samenwerken met anderen zijn een aantal voorbeelden. 
                </p>
                <p>
                    Elk schooljaar komt er iemand langs die over zijn beroep komt vertellen. Zo krijgen de kinderen een beter beeld bij het beroep dat iemand doet. En kunnen ze aan de hand van casussen samenwerken met anderen om zo hun sociale vaardigheden te trainen.
                </p>
                <!-- <a href="" class="hero-btn red-btn">Steun ons</a> -->
            </div>
            <div class="about-col image-col-right">
                <img id="agents-sova-img" src="../assets/images/agenten.jpeg">
                <!-- <i class="fa-solid fa-question"></i> -->
            </div>
        </div>
    </section>

</template>

<script>
export default {
name: "SoVaSection"
}
</script>

<style scoped>
.sova {
    width: 80%;
    margin: auto;
    padding: 20px 0 80px;
}
.about-col {
    flex-basis: 48%;
    padding: 30px 2px;
}
.row .image-col-right{
  display: flex;
  justify-content: center;
}
.about-col img {
    width: 100%;
    max-width: 600px;
}
.about-col h1 {
    padding-top: 0;
}
.about-col p {
    padding: 15px 0 0px;
}

#agents-sova-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.row .image-col-right{
  display: flex;
  justify-content: center;
}

@media(max-width: 978px) {
    .sova {
        padding: 0 0 60px;       /* t lr b */
    }
    .about-col {
        flex-basis: 48%;
        padding: 10px 2px;
    }
    .row {
        flex-direction: column;
        margin-top: 23px;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    h3 {
        font-size: 16px;
    }

    .about-col img {
        max-width: 100%;
    }

    .about-col p {
        padding: 7px 0px 0px;
    }

    #agents-sova-img {
        height: 200px;
    }

}
</style>