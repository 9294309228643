<template>
  <div>
    <VueDatePicker name="geboortedatum" locale="nl" :placeholder="placeholder" :enable-time-picker="false" :format="format" v-model="date" position="left" hide-input-icon input-class-name="dp-custom-input" @closed="$emit('validate')" @open="$emit('remove-css-class')" @blur="blurTriggered" @focus="focusTriggered" auto-apply></VueDatePicker>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "DatePicker",
  components: { VueDatePicker },
  data() {
    return {
      date: null,
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

       return `${day}/${month}/${year}`;
    }
  }
},
props: {
        placeholder: {
            type: String,
            default: 'Geboortedatum'
        },
        customClass: {
          type: String,
            default: ''
        } 
    },
    methods: {
      blurTriggered(){
        const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
        
        setTimeout(() => dateOfBirthInput.classList.remove('dp__input'),
        dateOfBirthInput.classList.add('dp__input_new'),
        dateOfBirthInput.style.height="44px", 10);
      },
      focusTriggered() {
        const dateOfBirthInput = document.getElementsByClassName("dp-custom-input")[0];
        
        setTimeout(() => dateOfBirthInput.classList.remove('dp__input'),
        dateOfBirthInput.classList.add('dp__input_new'),
        dateOfBirthInput.style.height="44px", 10);
      }
    }
  
}
</script>

<style>
:root {
  --placeholder-color: #808080;
}
.dp-custom-input {
    font-family: 'Poppins';
    font-size: 13px;
}
.dp-custom-input::-webkit-input-placeholder {
    color: var(--placeholder-color);
}
.error::-webkit-input-placeholder {
    color: red;
}
.dp__action_buttons {
  width: 100%;
}
input.dp__pointer.dp__input_readonly.dp__input.dp__input_reg.dp-custom-input {
  all: initial;
}
input.dp__pointer.dp__input_readonly.dp__input.dp__input_reg.dp-custom-input {
  width: 100%;
    padding: 10px;
    margin: 15px 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: #F0F0F0;
    resize: none;

    font-family: 'Poppins';
    font-size: 13px;
    height: 44px;
}
input.dp__pointer.dp__input_readonly.dp__input.dp__input_reg.dp-custom-input::-webkit-input-placeholder {
  color: #000;
}
</style>