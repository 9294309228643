<template>
     <div class="approach-container">
        <section class="approach">
            <div class="row reverse">
                <div class="about-col">
                    <img src="../assets/images/zahra-1-op-1.jpeg" id="zahra-1-op-1-img">
                </div>
                <div class="about-col">
                    <h1>Aanpak</h1>
                        <p>Er worden wekelijks lessen aangeboden aan leerlingen van groep 7 en 8. In groepen van ongeveer 11 leerlingen wordt gewerkt aan verschillende onderdelen, o.a. begrijpend lezen, spelling, taal en rekenen. De lessen worden gegeven door hoogopgeleide vrijwilligers die een grote affiniteit hebben met onderwijs en kinderen. </p>

                        <p>De leerlingen die een extra steuntje in de rug nodig hebben krijgen een persoonlijke begeleider. Zo kunnen zij op hun eigen tempo de les stof tot zich nemen. Iedere week hebben deze leerlingen een contactmoment met de begeleiders. In dit gesprek wordt de planning en de doelstelling van de week besproken. De begeleiders houden de voortgang van deze leerlingen gedurende het jaar in de gaten. Dit wordt uitvoerig besproken met de ouders en scholen.</p>
                            
                        <p>De huiswerkbegeleiding groep is bedoeld voor leerlingen van alle leeftijden van het voortgezet onderwijs. Het doel is om hen te helpen bij het plannen en organiseren van hun huiswerk, het vergroten van hun studievaardigheden en het versterken van hun zelfvertrouwen. Op deze manier worden de leerlingen gestimuleerd om het beste uit zichzelf te halen.</p>
                       
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "ApproachSection"
}
</script>

<style scoped>
.approach-container {
    background-color: #f2f2f2;
}
.approach {
    width: 80%;
    margin: auto;
    padding: 20px 0 50px;
}
.about-col {
    flex-basis: 48%;
    padding: 30px 2px;
}
.row .image-col-right{
  display: flex;
  justify-content: center;
}
.about-col img {
    width: 100%;
    max-width: 600px;
}
.about-col h1 {
    padding-top: 0;
}
.about-col p {
    padding: 15px 0 0px;
}

#zahra-1-op-1-img  {
    width: 100%;
    height: 400px;
    object-fit: cover;
}


@media(max-width: 978px) {
    .approach-container {
        padding: 5px 0 0;
    }
    .approach {
        padding: 10px 0 34px; 
    }
    .about-col {
        flex-basis: 48%;
        padding: 10px 2px;
    }
    .row {
        flex-direction: column;
        margin-top: 10px;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    h3 {
        font-size: 16px;
    }

    .about-col img {
        max-width: 100%;
    }

    .about-col p {
        padding: 7px 0px 0px;
    }
    .reverse {
        flex-direction: column-reverse;
    }

    #zahra-1-op-1-img  {
         height: 200px;
    }

}
</style>