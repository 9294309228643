<template>
        <WhyStichting550Section class="hidden"/>
        <MissionAndVisionSection class="hidden"/>
        <GoalsSection class="hidden"/>
        <ApproachSection class="hidden"/>
        <SovaSection class="hidden"/>
</template>

<script>
import WhyStichting550Section from '../components/WhyStichting550Section.vue';
import MissionAndVisionSection from '../components/MissionAndVisionSection.vue';
import GoalsSection from '../components/GoalsSection.vue';
import ApproachSection from '../components/ApproachSection.vue';
import SovaSection from '../components/SovaSection.vue';




export default {
  name: "AboutUs",
  components: {
    WhyStichting550Section,
    MissionAndVisionSection,
    GoalsSection,
    ApproachSection,
    SovaSection
  },
  mounted(){
    this.$store.commit('updateHeaderTitle', 'Over ons')
    this.$store.commit('updateHeaderSubtitle', 'Al meer dan 10 jaar begeleiden wij vrijwillig kinderen met een leerachterstand om zo het verschil te maken.')
    this.$store.commit('updateHeaderBtn', false)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Over ons - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Al meer dan 10 jaar begeleiden wij vrijwillig kinderen met een leerachterstand om zo het verschil te maken.");

  }
}
</script>

<style scoped>
.hidden {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1.5s;
}

.show {
  opacity: 1;
  transform: translateY(0);
}
</style>