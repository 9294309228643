<template>
        <div class="team-col">
            <router-link @click="scrollToTop()" :to="`/team-lid/${activeMember}`">
                <img :src="require(`../assets/images/${image}`)"
                :style="[activeMember == 'zahra-nassralla' || activeMember == 'sarah-zanky' || activeMember == 'mohamed-al-saady' || activeMember == 'taha-alkaduhimi' || image == 'no-picture.png' ? {'object-fit': 'cover'}: '']">
                <h3>{{ name }}</h3>
                <span>{{ branche }}</span>
            </router-link>
        </div>
</template>

<script>
export default {
name: "MemberCard",
  props: {
        name: {
            type: String,
            default: 'ALI AL-SAADY'
        }, 
        branche: {
            type: String,
            default: 'VESTIGING DEN HAAG'
        },
        image: {
            type: String,
            default: 'foto-ali-alsaady.jpg'
        },
        activeMember: {
            type: String,
            default: 'ali-al-saady'
        }
    },
  methods: {
    scrollToTop() {
          window.scrollTo(0,0);
      }
  }
}
</script>

<style>
.team-col {
    flex-basis: 31%;
    background: #FFF3F3;
    border-radius: 10px;
    box-sizing: border-box;
    transition: 0.5s;
    cursor: pointer;
    max-width: 375px;
}
.team-col:hover {
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
    transform: translateY(-3px);
}
.team-col a h3{
    color: #000000;
}
.team-col a span{
    color: #606060;
    font-size: 14px;
}
.team-col img {
    width: 200px;
    height: 270px;
}
@media(max-width: 978px) {
    .team-col {
        max-width: 300px;
        margin: auto;
        margin-bottom: 40px;
    }
}
</style>