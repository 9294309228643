<template>
    <section class="support-us-thank-you hidden">
        <h1>Bedankt</h1>
        <p>We zijn ontzettend blij met jouw donatie! Dit helpt ons om te blijven bestaan dankjewel!</p>
        <div class="gif-container">
            <img src="../assets/images/hearts-thank-you.gif">
        </div>
    </section>
</template>

<script>
export default {
name: "ThankYou",
data() {
    return {
        showBankDetails: false
  }
},
methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        toggleBankDetails() {
            this.showBankDetails = !this.showBankDetails
        }
    },
mounted(){
    this.$store.commit('updateHeaderTitle', 'Dankjewel')
    this.$store.commit('updateHeaderSubtitle', 'Uw bijdrage gaat 100% naar de kinderen aangezien alle begeleiders en bestuursleden zich geheel vrijwillig inzetten voor de stichting.')
    this.$store.commit('updateHeaderBtn', false)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Steun ons - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Uw bijdrage gaat 100% naar de kinderen aangezien alle begeleiders en bestuursleden zich geheel vrijwillig inzetten voor de stichting.");

  }
}
</script>

<style scoped>
.support-us-thank-you {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 0px;        /* t lr b */
}
.gif-container {
    height: 250px;
}
.gif-container img {
    height: 350px;
    position: relative;
    top: -60px;
    z-index: -3;
    background-color: pink;
}

.active {
    opacity: 1;
}

@media(max-width: 978px) {

    .support-us-thank-you {
        padding: 40px 0 0px;        /* t lr b */
    }
    .gif-container {
    height: 200px;
}
    .gif-container img {
        height: 250px;
        top: -40px;
    }

}
</style>