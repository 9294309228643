<template>
    <section class="support-us hidden">
        <h1>Doneer</h1>
        <p>Geef hieronder aan op welke manier je wilt doneren</p>
        <div class="row">
            <div class="support-col-container" @click="toggleBankDetails">
                <div class="support-col" >
                    <div class="transfer-container">
                        <div class="bank-choice" :style= "[showBankDetails ? {'opacity': '0'} : '']" :class="!showBankDetails ? 'active' : ''">
                            <div v-if="!showBankDetails">
                                <h3>Overschrijving</h3>
                                <p>Maak een bedrag over naar onze bankrekening</p>  
                            </div>
                        </div>

                        <div class="bank-info" :class="showBankDetails ? 'active' : ''">
                            <div v-if="showBankDetails">
                                <h3>Bankgegevens</h3>
                                <p>                    
                                    IBAN: NL80 INGB 0007162515 <br>
                                    BIC: INGBNL2A <br>
                                    T.N.V. Stichting 550
                                </p>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a href="https://paymentlink.mollie.com/payment/pr86FGt0VI9jTTSdGIiTA/" target="_blank" class="support-col-container">
                <div class="support-col">
                        <h3>iDEAL</h3>
                        <p>Doneer eenmalig een bedrag naar keuze met iDEAL</p>
                </div>
            </a>

            <router-link @click="scrollToTop" to="/steun-ons/periodieke-donatie" class="support-col-container">

                <div class="support-col">
                        <h3>Periodiek</h3>
                        <p>Doneer periodiek een bedrag naar keuze via automatische incasso</p>
                </div>
            </router-link> 

        </div>
        <!-- <div class="row">
            <div class="bank-details-container">            
                <p>Of maak zelf een bedrag over naar onze bankrekening</p>
                <div class="bank-details">
                    Stichting 550<br>
                    Rekeningnummer: 0007162515 <br>
                    IBAN: NL80 INGB 0007162515 <br>
                    BIC: INGBNL2A <br>
                    Bank: ING Bank N.V.
                </div>
             </div>
        </div> -->
    </section>
</template>

<script>
export default {
name: "SupportUs",
data() {
    return {
        showBankDetails: false
  }
},
methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        toggleBankDetails() {
            this.showBankDetails = !this.showBankDetails
        }
    },
mounted(){
    this.$store.commit('updateHeaderTitle', 'Steun ons')
    this.$store.commit('updateHeaderSubtitle', 'Uw bijdrage gaat 100% naar de kinderen aangezien alle begeleiders en bestuursleden zich geheel vrijwillig inzetten voor de stichting.')
    this.$store.commit('updateHeaderBtn', false)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Steun ons - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Uw bijdrage gaat 100% naar de kinderen aangezien alle begeleiders en bestuursleden zich geheel vrijwillig inzetten voor de stichting.");

  }
}
</script>

<style scoped>
.support-us {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 120px;        /* t lr b */
}

.support-col-container {
    flex-basis: 31%;
    background: #FFF3F3;
    border-radius: 10px;
    margin-top: 5px;
    box-sizing: border-box;
    transition: 0.5s;
    cursor: pointer;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    text-decoration: none;
}
.support-col-container:hover {
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
    transform: translateY(-3px);
}
.support-col h3, .support-col .transfer-container h3{
    color: #000000;
    font-size: 24px;
    font-weight: 600;
}
.support-col p, .support-col .transfer-container p{
    color: #000000;
    font-size: 16px;
    font-weight: 400; 
    /* background-color: red; */
}

.row .bank-details-container {
    margin: auto;
    margin-top: 5px;
}

.row .bank-details {
    margin: 12px auto 0px;
    text-align: left;
    color: #606060;
    font-size: 14px;
    width: fit-content;
}

.bank-choice {
    transition: 3s;
}
.bank-info{
    opacity: 0;
    transition: 3s;
}

.active {
    opacity: 1;
}

@media(max-width: 978px) {
    .support-col-container {
        margin: -10px 0px 50px;
    }
    .support-us {
        padding: 40px 0 40px;        /* t lr b */
    }
    .row {
        flex-direction: column;
    }
    h1 {
        font-size: 20px;
    }
    .row .support-col h3 {
        font-size: 18px;
    }

    .row .bank-details-container {
        margin-top: -50px;
        text-align: left;
    }

    .row .bank-details {
        width: 100%;
        padding-left: 5px;

    }

}
</style>