<template>
      <section class="team hidden">
        <h1>Ontmoet onze mensen</h1>
        <p>De toppers die elke week weer klaar staan voor de kinderen</p>
        <div class="row">
            <MemberCard :name="'Mohamed Al-Saady'"
                        :branche="'Vestiging Rotterdam'"
                        :image="'mohamed-al-saady.jpg'"
                        :activeMember="'mohamed-al-saady'"/>
            <MemberCard :name="'Mohamed Alkaduhimi'"
                        :branche="'Vestiging Amsterdam'"
                        :image="'mohamed-alkaduhimi.png'"
                        :activeMember="'mohamed-alkaduhimi'"/>
            <MemberCard :name="'Zahra Nassralla'"
                        :branche="'Vestiging Den Haag'"
                        :image="'zahra-nassralla.png'"
                        :activeMember="'zahra-nassralla'"/>
        </div>
        <div class="row">
            <MemberCard :name="'Ahmed Jabori'"
                        :branche="'Vestiging Den Haag'"
                        :image="'no-picture.png'"
                        :activeMember="'ahmed-jabori'"/>
            <MemberCard :name="'Sarah Zanky'"
                        :branche="'Vestiging Den Haag'"
                        :image="'sarah-zanky.png'"
                        :activeMember="'sarah-zanky'"/>
            <MemberCard :name="'Ali Al-Saady'"
                        :branche="'Vestiging Rotterdam'"
                        :image="'ali-al-saady.jpg'"
                        :activeMember="'ali-al-saady'"/>
        </div>
        <div class="row">
            <MemberCard :name="'Taha Alkaduhimi'"
                        :branche="'Vestiging Amsterdam'"
                        :image="'taha-alkaduhimi.jpeg'"
                        :activeMember="'taha-alkaduhimi'"/>
            <MemberCard :name="'Ali Jabori'"
                        :branche="'Vestiging Den Haag'"
                        :image="'no-picture.png'"
                        :activeMember="'ali-jabori'"/>
            <MemberCard :name="'Osama Zanky'"
                        :branche="'Vestiging Rotterdam'"
                        :image="'no-picture.png'"
                        :activeMember="'osama-zanky'"/>
        </div>
        <div class="row">
            <MemberCard :name="'Ali Alawy'"
                        :branche="'Vestiging Rotterdam'"
                        :image="'no-picture.png'"
                        :activeMember="'ali-alawy'"/>
            <MemberCard :name="'Dalia Al-Rawaf'"
                        :branche="'Vestiging Den Haag'"
                        :image="'no-picture.png'"
                        :activeMember="'dalia-al-rawaf'"/>
            <MemberCard :name="'Nour Alkaduhimi'"
                        :branche="'Vestiging Amsterdam'"
                        :image="'no-picture.png'"
                        :activeMember="'nour-alkaduhimi'"/>
        </div>
        <div class="row">
            <MemberCard :name="'Yasser Zanky'"
                        :branche="'Vestiging Rotterdam'"
                        :image="'no-picture.png'"
                        :activeMember="'yasser-zanky'"/>
        </div>
    </section>
</template>

<script>
import MemberCard from "@/components/MemberCard.vue";
export default {
    name: "TheTeam",
    components: {
        MemberCard,
    },
    mounted(){
        this.$store.commit('updateHeaderTitle', 'Het team')
        this.$store.commit('updateHeaderSubtitle', 'Ons team bestaat uit individuen met diverse achtergronden, verenigd door een gedeelde missie: Wij streven naar een betere startpositie voor kinderen met een leerachterstand.')
        this.$store.commit('updateHeaderBtn', false)

        const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Het team - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Ons team bestaat uit individuen met diverse achtergronden, verenigd door een gedeelde missie: Wij streven naar een betere startpositie voor kinderen met een leerachterstand.");
    },
}
</script>

<style>
/* The Team Page */
.team {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 180px;        /* t lr b */
}
.team .row {
    column-gap: 50px;
    margin-top: 50px;
}
.team .row a {
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    padding: 43px 40px 35px;
    text-decoration: none;
}

@media(max-width: 978px) {
    .team {
        padding: 40px 0 60px;        /* t lr b */
    }
    .team .row {
        margin-top: 0px;
    }
    .team p {
        margin-bottom: 20px;
    }
}
</style>