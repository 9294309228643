<template>
  <div class="container" @click="toggleDropDown" v-click-outside-element="closeDropDown">  
        <span class="select-text" :style="[valid ? selectedValue != 'Selecteer klas' ? {'color': '#000'} : {'color': '#808080'} : {'color': 'red'}]">{{ selectedValue }}</span>
        <ul v-if="showDropDownMenu" class="dropdown-menu">
          <li @click="updateSelectedValue('Groep 7')" :class="selectedValue == 'Groep 7' ? 'active' : ''">Groep 7</li>
          <li @click="updateSelectedValue('Groep 8')" :class="selectedValue == 'Groep 8' ? 'active' : ''">Groep 8</li>
          <li @click="updateSelectedValue('Middelbare school')" :class="selectedValue == 'Middelbare school' ? 'active' : ''" >Middelbare school</li>
        </ul>
        <i id="dropdown-field-icon" class="fa-solid fa-chevron-down"></i>
        <input type="hidden" name="klas" :value="selectedValue">
  </div>
</template>

<script>
export default {
name: "DropDown",
data() {
    return {
        showDropDownMenu: false,
        selectedValue: "Selecteer klas",
        valid: true
    }
  },
  methods: {
    toggleDropDown(){
        this.showDropDownMenu = !this.showDropDownMenu
    },
    updateSelectedValue(value){
        this.selectedValue = value
        this.validate();
    },
    closeDropDown(){
        if(this.showDropDownMenu == true) {
            this.showDropDownMenu = false
            this.validate()
        }

    },
    validate(){
        if(this.selectedValue == "Selecteer klas" || this.selectedValue == "Klas selecteren is verplicht"){
            this.selectedValue = "Klas selecteren is verplicht"
            this.valid = false
        }
        else {
            this.valid = true
        }
    }
  }
}
</script>

<style>
.container {
    background-color: #F0F0F0;
    height: 20px;
    padding: 10px;
    text-align: left;
    margin: 15px 0;
    display: flex;
    cursor: pointer;
    position: relative;
}
.select-text {
    font-size: 13.2px;
    color: #808080;
}

.dropdown-menu {
    position: absolute;
    top:40.5px;
    background-color: #fff;
    width: 100%;
    left: 0;
    margin-top: 1px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0 1px 2px 2px;
    overflow: hidden;
    display: block;
    max-height: 144px;
    overflow-y: auto;
    z-index: 9;
    font-size: 13px;
}
.dropdown-menu li {
    padding: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer
} 
.dropdown-menu {
    padding: 0;
    list-style: none
}
.dropdown-menu li:hover {
    background-color: #f2f2f2
}
.dropdown-menu .active {
    background-color: #F5F5F5;

}
#dropdown-field-icon {
    /* background-color: red; */
    color: #606060;

    position: absolute;
    bottom: 11px;
    right: 15px;
}
</style>