<template>
    <div class="mission-and-vision-container">
        <section class="mission-and-vision">
            <div class="row reverse">
                <div class="about-col">
                    <img src="../assets/images/kinderen-aan-het-werk.jpeg" id="kids-working-img">
                </div>
                <div class="about-col">
                    <h1>Missie en visie</h1>
                    <p>Onze missie is jonge kinderen met een onderwijsachterstand, die veelal in achtergestelde wijken wonen, een betere startpositie te geven zodat zij met minder achterstand in de ontwikkeling kunnen starten met het voorgezet onderwijs. Vervolgens willen we de kinderen gestructureerd begeleiden in het voorgezet onderwijs om zodoende een maximale ontwikkeling van de kinderen te benutten en tevens de talenten van de kinderen te stimuleren.</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
name: 'MissionAndVisionSection'
}
</script>

<style scoped>
.mission-and-vision-container {
    background-color: #f2f2f2;
}
.mission-and-vision {
    width: 80%;
    margin: auto;
    padding: 20px 0 50px;
}
.about-col {
    flex-basis: 48%;
    padding: 30px 2px;
}
.row .image-col-right{
  display: flex;
  justify-content: center;
}
.about-col img {
    width: 100%;
    max-width: 600px;
}
.about-col h1 {
    padding-top: 0;
}
.about-col p {
    padding: 15px 0 0px;
}

#kids-working-img  {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

@media(max-width: 978px) {
    .mission-and-vision-container{
        padding: 5px 0 0;
    }
    .mission-and-vision {
        padding: 10px 0 34px; 
}
    .about-col {
        flex-basis: 48%;
        padding: 10px 2px;
    }
    .row {
        flex-direction: column;
        margin-top: 10px;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    h3 {
        font-size: 16px;
    }

    .about-col img {
        max-width: 100%;
    }

    .about-col p {
        padding: 7px 0px 0px;
    }
    .reverse {
        flex-direction: column-reverse;
    }
    #kids-working-img {
        height: 200px;
    }

}
</style>