<template>
    <!-- Register Child -->
    <section class="register-child hidden">
        <h1>Aanmelden</h1>
        <p>Er zijn maar een beperkt aantal plaatsen beschikbaar dus wees er snel bij</p>
        <!-- <p>De inschrijvingen openen <strong>zaterdag 10 augustus</strong> om <strong>09:00</strong></p> -->
        <!-- <p>De inschrijvingen openen binnenkort!</p> -->

        <div class="row">
            <div class="register-child-col">
                <h3>Wat wij verwachten</h3>
                <div class="bullet-points-container">
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Uw kind zit in groep 7, groep 8, of in het voortgezet onderwijs</span>
                    </div>
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Uw kind is wekelijks aanwezig </span>
                    </div>   
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Uw kind neemt een positieve houding aan</span>
                    </div> 
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Uw kind vertoont geen ongewenst gedrag</span>
                    </div>   
                    <!-- <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, eos dolores accusamus eveniet fugiat aliquid cumque. </span>
                    </div>  
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit. </span>
                    </div>   -->
                </div>
 
            </div>
            <div class="register-child-col">
                <h3>Wat wij bieden</h3>
                <div class="bullet-points-container">
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Profiteer van begeleiding door professionals met een HBO- en universitaire achtergrond in diverse vakgebieden</span>
                    </div>
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Ontvang coaching om plannen te maken en efficiënt te werken</span>
                    </div>   
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Ervaar begeleiding in de ontwikkeling van sociale vaardigheden, zelfontplooiing en identiteitsontwikkeling </span>
                    </div>  
                    <div class="bullet-point">
                        <span><i class="fa-solid fa-check"></i></span>
                        <span>Maak kennis met nieuwe vrienden!</span>
                    </div>   
                </div>
            </div>
        </div>

        <div class="comment-box-container" v-if="!showThankYouMessage">
            <div class="comment-box">
                <h3>Schrijf uw kind in!</h3>
                <form ref="registerChildForm" @submit.prevent="sendEmail" method="POST" class="comment-form" id="register-child-form">
                    <input type="text" name="voornaam" :placeholder="firstnamePlaceholder" id="firstname-input" :class="!firstNameValid ? 'error' : ''" v-model="firstnameInput" @blur="validateFirstName()">
                    <input type="text" name="achternaam" :placeholder="lastnamePlaceholder" id="lastname-input" :class="!lastNameValid ? 'error' : ''" v-model="lastnameInput" @blur="validateLastName()">

                    <div class="email-field-container">
                        <input type="email" name="email" :placeholder="emailPlaceholder" id="email-input" :class="!emailValid ? 'error' : ''" v-model="emailInput" @blur="validateEmail()">
                        <i @click="GiveEmailInputFocus()" class="fa-solid fa-envelope" id="email-field-icon"></i>
                    </div>

                    <input type="tel" name="telefoonnummer" :placeholder="phonePlaceholder" id="phone-input" :class="!phoneValid ? 'error' : ''" v-model="phoneInput" @blur="validatePhone()">
                    
                    <input type="text" name="adres" :placeholder="addressPlaceholder" id="address-input" :class="!addressValid ? 'error' : ''" v-model="addressInput" @blur="validateAddress()">

                    <input type="text" name="postcode_woonplaats" :placeholder="zipcodeCityPlaceholder" id="zipcode-city-input" :class="!zipcodeCityValid ? 'error' : ''" v-model="zipcodeCityInput" @blur="validateZipcodeCity()">

                    <DropDown ref="DropDownComponent"/>

                    <input type="text" name="school" :placeholder="schoolPlaceholder" id="school-input" :class="!schoolValid ? 'error' : ''" v-model="schoolInput" @blur="validateSchool()">

                    <div class="radio-btns-branches-container">
                        <div class="field-title">
                            Kies een vestiging
                        </div>
                        <div class="radio-btns-branches">
                            <!-- <div class="option-container">
                                <input type="radio" id="branche-denhaag" name="vestiging" value="Den Haag" v-model="chosenBranche" @change="validateBranches()">
                                <label for="branche-denhaag">Den Haag</label><br>
                            </div> -->
                            <div class="option-container">
                                <input type="radio" id="branche-amsterdam" name="vestiging" value="Amsterdam" v-model="chosenBranche" @change="validateBranches()">
                                <label for="branche-amsterdam">Amsterdam</label><br>  
                            </div>
                            <div class="option-container">
                                <input type="radio" id="branche-rotterdam" name="vestiging" value="Rotterdam" v-model="chosenBranche" @change="validateBranches()">
                                <label for="branche-rotterdam">Rotterdam</label><br>  
                            </div>
                            <span id="branches-error" :style="[!brancheValid ? {'display': 'block'} : {'display': 'none'}]">Kies een vestiging</span>
                        </div>
                    </div>

                    <div class="radio-btns-socialmedia-container">
                        <div class="field-title">
                           Mag uw kind op social media komen?
                        </div>
                        <div class="radio-btns-socialmedia">
                            <div class="option-container">
                                <input type="radio" id="socialmedia-yes" name="magopsocialmedia" value="Ja" v-model="socialmediaAllowed" @change="validateSocialMediaAllowed()">
                                <label for="socialmedia-yes">Ja</label><br>
                            </div>
                            <div class="option-container">
                                <input type="radio" id="socialmedia-no" name="magopsocialmedia" value="Nee" v-model="socialmediaAllowed" @change="validateSocialMediaAllowed()">
                                <label for="socialmedia-no">Nee</label><br>  
                            </div>
                            <span id="socialmedia-error" :style="[!socialmediaAllowedValid ? {'display': 'block'} : {'display': 'none'}]">Maak een keuze</span>
                        </div>
                    </div>
                    <button type="submit" class="hero-btn red-btn">Inschrijven</button>
                    <!-- <button type="reset" class="hero-btn-disabled red-btn">Inschrijven</button> -->
                </form>
            </div>
        </div>

        <div v-if="showThankYouMessage" class="thank-you-message-register-child">
                <h5 class="title">Bedankt voor uw inschrijving</h5>
                <div class="message">
                    <div class="greeting">Beste ouder,</div>
                    <div class="content"> Bedankt voor uw inschrijving, binnen enkele ogenblikken ontvangt u een bevestigingsmail. We zullen z.s.m contact met u opnemen.</div>
                    <div class="end-greeting">Met vriendelijke groet,</div>
                    <div>Team Stichting550</div>
                </div>
        </div>
    </section>
</template>

<script>
import DropDown from "@/components/DropDown.vue";
import emailjs from '@emailjs/browser';

export default {
name: "RegisterChild",
components: {
    DropDown
},
data() {
    return {
        firstnameInput: '',
        firstnamePlaceholder: 'Voornaam',
        lastnameInput: '',
        lastnamePlaceholder: 'Achternaam',
        emailInput: '',
        emailPlaceholder: 'Email ouder',
        phoneInput: '',
        phonePlaceholder: 'Telefoonnummer ouder',
        addressInput: '',
        addressPlaceholder: 'Adres',
        zipcodeCityInput: '',
        zipcodeCityPlaceholder: 'Postcode en woonplaats',
        schoolInput: '',
        schoolPlaceholder: 'School',
        chosenBranche: '',
        socialmediaAllowed: '',
        firstNameValid: true,
        lastNameValid: true,
        emailValid: true,
        phoneValid: true,
        addressValid: true,
        zipcodeCityValid: true,
        schoolValid: true,
        brancheValid: true,
        socialmediaAllowedValid: true,
        selectClassValid: true,
        showThankYouMessage: false
    }
  },
  methods: {
    validateFirstName(){
            if(this.firstnameInput == '') {
                this.firstNameValid= false;
                this.firstnamePlaceholder = 'Voornaam is verplicht'
            }
            else {
                this.firstNameValid = true;
            } 
        },
    validateLastName(){
            if(this.firstnameInput == '') {
                this.lastNameValid= false;
                this.lastnamePlaceholder = 'Achternaam is verplicht'
            }
            else {
                this.lastNameValid = true;
            } 
        },
    validateEmail(){
        var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(this.emailInput == '') {
            this.emailValid= false;
            this.emailPlaceholder = 'Email ouder is verplicht'
        }          
         else if(!this.emailInput.match(validRegex)) {
            this.emailValid= false;
            this.emailPlaceholder = this.emailInput + ' is geen geldig email';
            this.emailInput = "";
            }
        else {
            this.emailValid = true;
        } 
    },
    validatePhone(){
        var validRegex =  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        if(this.phoneInput == '') {
            this.phoneValid= false;
            this.phonePlaceholder = 'Telefoonnummer ouder is verplicht'
        }          
         else if(!this.phoneInput.match(validRegex)) {
                this.phoneValid= false;
                this.phonePlaceholder = this.phoneInput + ' is geen geldig telefoonnummer';
                this.phoneInput = "";
            }
        else {
            this.phoneValid = true;
        } 
    },
    validateAddress(){
            if(this.addressInput == '') {
                this.addressValid= false;
                this.addressPlaceholder = 'Adres is verplicht'
            }
            else {
                this.addressValid = true;
            } 
        },
    validateZipcodeCity(){
            if(this.zipcodeCityInput == '') {
                this.zipcodeCityValid= false;
                this.zipcodeCityPlaceholder = 'Postcode en woonplaats is verplicht'
            }
            else {
                this.zipcodeCityValid = true;
            } 
        },
    validateBranches(){
            if(!this.chosenBranche ) {
                this.brancheValid = false;
            }
            else {
                this.brancheValid = true;
            } 
        },
    validateSocialMediaAllowed() {
        if(!this.socialmediaAllowed) {
            this.socialmediaAllowedValid = false;
        } 
        else {
            this.socialmediaAllowedValid = true;
        }
    },
    validateClass(){
            this.$refs.DropDownComponent.validate();

            if(this.$refs.DropDownComponent.valid == true) {
                this.selectClassValid = true;
            }
            else {
                this.selectClassValid = false;

            }
        },
    validateSchool(){
        if(this.schoolInput == '') {
                this.schoolValid= false;
                this.schoolPlaceholder = 'School is verplicht'
            }
            else {
                this.schoolValid = true;
            } 
    },
        sendEmail() {
            this.validateFirstName();
            this.validateLastName();
            this.validateEmail();
            this.validatePhone();
            this.validateAddress();
            this.validateZipcodeCity();
            this.validateBranches();
            this.validateSocialMediaAllowed();
            this.validateClass();
            this.validateSchool();

            if(this.firstNameValid && this.lastNameValid && this.emailValid &&
               this.phoneValid && this.addressValid && this.zipcodeCityValid && 
               this.brancheValid && this.socialmediaAllowedValid && this.selectClassValid && this.schoolValid) {
                    emailjs.sendForm('register_child', 'register_child_form', this.$refs.registerChildForm, 'dlN19rtqTHTkHae61')
                    .then((result) => {
                        console.log('SUCCESS!', result.text);
                        this.showThankYouMessage = true
                    }, (error) => {
                        console.log('FAILED...', error.text);
                        this.showThankYouMessage = false
                    });
            } 
 
    },
        GiveEmailInputFocus() {
            const emailInput = document.getElementById("email-input");
            emailInput.focus();
        },
  },
mounted(){
    this.$store.commit('updateHeaderTitle', 'Meld uw kind aan')
    this.$store.commit('updateHeaderSubtitle', 'Bij Stichting550 wordt er geen onderscheid gemaakt in ras, cultuur of afkomst. Elk kind is uniek en heeft recht op bijles.')
    this.$store.commit('updateHeaderBtn', false)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
          /* to show animation multiple times */
          // else {
          //   entry.target.classList.remove('show')
          // }
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Meld uw kind aan - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Bij Stichting550 wordt er geen onderscheid gemaakt in ras, cultuur of afkomst. Elk kind is uniek en heeft recht op bijles.");

      
  }
}
</script>

<style scoped>
.register-child {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 60px;        /* t lr b */
}
.register-child-col {
    flex-basis: 40%;
    background: #FFF3F3;
    border-radius: 10px;
    /* margin-top: 5px; */
    box-sizing: border-box;
    transition: 0.5s;
    /* cursor: pointer; */
    padding: 15px 20px;
    text-align: left;
    min-width: 35vw;
}
.register-child-col .bullet-points-container {
    width: fit-content;
    margin: auto;
}
.register-child-col .bullet-point {
    display: flex;
    column-gap: 2px;
    margin-bottom: 10px;
}

.register-child-col .fa-check {
    padding-right: 10px;
}

.register-child-col h3{
    color: #000000;
    margin-bottom: 15px;
}


.comment-box-container {
    /* background-color: pink; */
    margin: 80px auto;
}
.comment-box-container h3 {
    /* background-color: red; */
    font-size: 28px;
}
.comment-box {
    border: 1px solid #ccc;
    padding: 10px 20px;
    max-width: 500px;
    /* width: 50%; */
    margin: auto;
}
.comment-box h3 {
    font-size: 20px;
    margin-bottom: 20px;
}
.comment-form input, .comment-form textarea {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: #F0F0F0;
    resize: none;
}
.comment-form button {
    margin: 25px auto 10px;
    display: block;
}
.error::-webkit-input-placeholder {
    color: red;
}
.date-of-birth-container {
    position: relative;
}
#calendar-icon{
    color: #606060;
    position: absolute;
    bottom: 29px;
    right: 15px;
    cursor: pointer;
}
.email-field-container {
    position: relative;
}
#email-field-icon{
    color: #606060;
    position: absolute;
    bottom: 25px;
    right: 15px;
}
/* radio buttons */


/* radio buttons branches and social media allowed */
.radio-btns-branches-container, .radio-btns-socialmedia-container  {
    margin-top: 8px;
    padding-left: 10px;
}
.radio-btns-socialmedia-container {
    /* background-color: pink; */
    margin-top:14px;
}
.radio-btns-branches-container .field-title, .radio-btns-socialmedia-container .field-title {
    text-align: left;
    font-size: 14px;
    margin-bottom: 3px;
    max-width: 400px;
}
.radio-btns-branches, .radio-btns-socialmedia {
    display: flex;
    column-gap: 18px;
    flex-wrap: wrap;
}

.option-container{
    /* background-color: red; */
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
}
.option-container input[type='radio'] {
    width: 12px;
    height: 12px;
    background-color: lightblue;
}
.option-container input[type='radio']:hover{
    cursor: pointer;
}
.option-container label {
    padding-left: 10px;
    font-size: 13px;
    cursor: pointer;
}
.radio-btns-branches #branches-error, .radio-btns-socialmedia #socialmedia-error{
    font-size: 13.5px;
    color: red;
    display: none;
    align-self: center; 
    margin-left: auto;
}


.hero-btn {
        display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 12px 34px;
        font-size: 16px;
        background: transparent;
        position: relative;
        cursor: pointer;
    }
.hero-btn:hover {
    border: 1px solid #F44336;
    background: #F44336;
    transition: 1s;
}

.hero-btn-disabled {
    display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 12px 34px;
        font-size: 16px;
        background: transparent;
        position: relative;
        cursor: pointer;
}

.hero-btn-disabled:hover {
    border: 1px solid #606060;
    background: #606060;
    transition: 1s;
    cursor: not-allowed;
}

.red-btn {
    border: 1px solid #F44336;
    background: transparent;
    color: #F44336;
    margin-top: 30px;
}
.red-btn:hover {
    color: #FFF;
}
.dp-custom-input {
    /* height: 300px; */
}

.thank-you-message-register-child {
    text-align: left;
    display: block;
    margin: auto;
    width: fit-content;
    margin: 80px auto;
}
.thank-you-message-register-child .title {
    font-size: 24px;
    padding-bottom: 15px;
    color: #555;
    font-weight: 400;
}
.thank-you-message-register-child .message {
    color: #606060;
}
.thank-you-message-register-child .message .greeting{
    padding-bottom:15px;
}
.thank-you-message-register-child .message .content{
    padding-bottom:15px;
}
.thank-you-message-register-child .message .end-greeting{
    padding-bottom:5px;
}


@media(max-width: 978px){
    .register-child {
        padding: 40px 0 60px;        /* t lr b */
    }
    .register-child-col {
        margin: -10px 0px 50px;
        min-width: auto;
    }
    .register-child-col .bullet-points-container{
        font-size: 14px;
        width: unset;
    }
    .radio-btns-branches, .radio-btns-socialmedia {
        flex-direction: column;
    }
    .radio-btns-branches #branches-error, .radio-btns-socialmedia #socialmedia-error{
        font-size: 13px;
        color: red;
        margin-left: 22px;
        align-self: start;
    }
    .radio-btns-branches #branches-error, .radio-btns-socialmedia #socialmedia-error{
        margin-top: 2px;
        margin-bottom: 5px;
    }

    .comment-box-container {
        margin: -8px auto 15px;
    }
    .comment-box h3 {
        font-size: 18px;
    }
    .thank-you-message-register-child {
        margin: 45px auto;
    }  
    .thank-you-message-register-child .title {
        font-size: 20px;
    }  
    
}
</style>