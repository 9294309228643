<template>
  <div>
    <router-link @click="scrollToTop()" :to="`${url}`" class="hero-btn red-btn">{{ text }}</router-link> 
  </div>
</template>

<script>
export default {
name: "OrangeWhiteBtn",
props: {
        text: {
            type: String,
            default: 'Steun ons'
        }, 
        linkType: {
            type: String,
            default: 'aTag'
        },
        url: {
            type: String,
            default: '/het-team'
        }
    },
methods: {
    scrollToTop() {
          window.scrollTo(0,0);
      }
    }
}
</script>

<style>
.hero-btn {
    display: inline-block;
    text-decoration: none;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: 12px 34px;
    font-size: 16px;
    background: transparent;
    position: relative;
    cursor: pointer;
}
.hero-btn:hover {
    border: 1px solid #F44336;
    background: #F44336;
    transition: 1s;
}
.red-btn {
    border: 1px solid #F44336;
    background: transparent;
    color: #F44336;
    margin-top: 30px;
}
.red-btn:hover {
    color: #FFF;
}
</style>