<template>
    <section class="contact-us hidden">
        <h1>Vragen?</h1>
        <p>Neem contact met ons op</p>
        <div class="row-contact">
            <div class="contact-col">
                <div>
                    <i class="fa-solid fa-house"></i>
                    <div class="branches-contact">
                        <!-- <span>
                            <h5>Mandelaplein 1</h5>
                            <p>2572 HT Den Haag</p>
                         </span> -->
                         <span>
                            <h5>Hillevliet 90</h5>
                            <p>3074 KD Rotterdam</p>
                         </span>
                        <span>
                            <h5>Jan Tooropstraat 6A</h5>
                            <p>1062 BM Amsterdam</p>
                        </span>
                    </div>
                </div>
                <div>
                    <i class="fa-solid fa-phone"></i>
                    <span>
                        <h5>0617451663</h5>
                        <p>Telefonisch bereikbaar of via whatsapp</p>                
                    </span>
                </div>
                <div>
                    <i class="fa-regular fa-envelope"></i>
                    <span>
                        <h5>info@stichting550.nl</h5>
                        <p>U kunt ons altijd mailen</p>
                    </span>
                </div>
            </div>
            <div class="contact-col" v-if="!showThankYouMessage">
                <form ref="contactForm" @submit.prevent="sendEmail" method="POST" id="contact-form" >
                    <input type="text" name="name" v-model="nameInput" :placeholder="namePlaceholder" :class="!nameValid ? 'error' : ''" @blur="validateName()">
                    <input type="email" name="email" v-model="emailInput" :placeholder="emailPlaceholder" :class="!emailValid ? 'error' : ''" @blur="validateEmail()">
                    <input type="text" name="subject" :placeholder="subjectPlaceholder">
                    <textarea rows="8" name="message" v-model="messageInput" :placeholder="messagePlaceholder" :class="!messageValid ? 'error' : ''" @blur="validateMessage()"></textarea>
                    <button type="submit" class="hero-btn red-btn">Verzenden</button>
                </form>
            </div>
            <div v-if="showThankYouMessage" class="thank-you-message-contact">
                    <h5 class="title">Bedankt voor je bericht</h5>
                    <div class="message">
                        <div class="greeting">Beste {{ nameInput }},</div>
                        <div class="content"> Bedankt voor je bericht. We zullen z.s.m contact met je opnemen.</div>
                        <div class="end-greeting">Met vriendelijke groet,</div>
                        <div>Team Stichting550</div>
                    </div>
            </div>
        </div>
    </section>

</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: "Contact",
  data() {
    return {
        nameInput: '',
        namePlaceholder: 'Naam',
        emailInput: '',
        emailPlaceholder: 'Email',
        subjectInput: '',
        subjectPlaceholder: 'Onderwerp',
        messageInput: '',
        messagePlaceholder: 'Bericht',
        nameValid: true,
        emailValid: true,
        messageValid: true,
        showThankYouMessage: false
    }
  },
  methods: {
    validateName(){
            if(this.nameInput == '') {
                this.nameValid= false;
                this.namePlaceholder = 'Naam is verplicht'
            }
            else {
                this.nameValid = true;
            } 
        },
    validateEmail(){
        var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(this.emailInput == '') {
            this.emailValid= false;
            this.emailPlaceholder = 'Email is verplicht'
        }          
         else if(!this.emailInput.match(validRegex)) {
            this.emailValid= false;
            this.emailPlaceholder = this.emailInput + ' is geen geldig email';
            this.emailInput = "";
            }
        else {
            this.emailValid = true;
        } 
    },
    validateMessage(){
            if(this.messageInput == '') {
                this.messageValid= false;
                this.messagePlaceholder = 'Bericht is verplicht'
            }
            else {
                this.messageValid = true;
            } 
        },
    validateForm(){

            this.validateName();
            this.validateEmail();
            this.validateMessage();

            if(this.nameValid && this.emailValid && this.messageValid){
                this.$refs.contactForm.submit();
            } 
        },
    sendEmail() {
            this.validateName();
            this.validateEmail();
            this.validateMessage();

            if(this.nameValid && this.emailValid && this.messageValid) {
                emailjs.sendForm('contact_service', 'contact_form', this.$refs.contactForm, 'i8ZhKrByztpUb6f3t')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    this.showThankYouMessage = true
                }, (error) => {
                    console.log('FAILED...', error.text);
                    this.showThankYouMessage = false
                });
            } 
    }
  },
  mounted(){
    this.$store.commit('updateHeaderTitle', 'Contact')
    this.$store.commit('updateHeaderSubtitle', 'Voor vragen en opmerkingen verwijzen we u graag naar het formulier hieronder.')
    this.$store.commit('updateHeaderBtn', false)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Contact - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Voor vragen en opmerkingen verwijzen we u graag naar het formulier hieronder.");
  }
}
</script>

<style scoped>
.row-contact {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.contact-us {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 130px;        /* t lr b */
}
.contact-col {
    flex-basis: 48%;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 20px;
}
.contact-col div {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.contact-col div .fa-solid, .contact-col div .fa-regular{
    font-size: 28px;
    color: #F44336;
    margin: 10px;
    margin-right: 30px;
}
.contact-col p {
    padding: 0;
}
.contact-col div h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
}
.contact-col input, .contact-col textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #CCC;
    box-sizing: border-box;
    resize: none;
}

.contact-col .branches-contact {
    /* background-color: red; */
    margin-bottom: 0px;
    display: flex;
    column-gap: 40px;
    /* flex-direction: column; */
}

.hero-btn {
        display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 12px 34px;
        font-size: 16px;
        background: transparent;
        position: relative;
        cursor: pointer;
    }
.hero-btn:hover {
    border: 1px solid #F44336;
    background: #F44336;
    transition: 1s;
}
.red-btn {
    border: 1px solid #F44336;
    background: transparent;
    color: #F44336;
    margin-top: 15px;
}
.red-btn:hover {
    color: #FFF;
}
.error::-webkit-input-placeholder {
    color: red;
}

.thank-you-message-contact {
    flex-basis: 48%;
    text-align: left;
}

.thank-you-message-contact .title {
    font-size: 24px;
    padding-bottom: 15px;
    color: #555;
    font-weight: 400;
}
.thank-you-message-contact .message {
    color: #606060;
}
.thank-you-message-contact .message .greeting{
    padding-bottom:15px;
}
.thank-you-message-contact .message .content{
    padding-bottom:15px;
}
.thank-you-message-contact .message .end-greeting{
    padding-bottom:5px;
}

@media(max-width: 978px) {
    .contact-us {
        padding: 40px 0 0px;        /* t lr b */
    }
    .contact-col:first-child {
        margin-bottom: 35px;
    }
    .contact-col {
        margin: -10px 0px 50px;
    }
    .contact-col .branches-contact {
        flex-direction: column;
        row-gap: 15px;
        align-items: flex-start;
    }
    .row-contact {
        flex-direction: column-reverse;
    }
    .thank-you-message-contact {
        padding-bottom: 50px;
    }
    .thank-you-message-contact .title {
        font-size: 20px;
    }
    .contact-col div .fa-solid, .contact-col div .fa-regular{
        font-size: 25px;
    }
    .contact-col div h5 {
        font-size: 17px;
    }
}
</style>