<template>
  <section class="goals">
    <div class="row">
            <div class="about-col">
                <h1>Doelstellingen</h1>
                    <p>Onderwijsachterstand bij basisschool leerlingen verkleinen</p>

                    <p>Ouders en leerlingen begeleiden bij de overgang naar het voorgezet onderwijs</p>
                    
                   <p> Leerlingen uit voortgezet onderwijs ondersteunen bij hun huiswerk</p>
                    
                    <p> Leerlingen ondersteunen bij het ‘leren-leren’</p>
                    
                    <p>Leerlingen ondersteunen bij het plannen</p>
                    
                    <p>Een omgeving creëren waar de leerlingen in aanraking komen met positieve rolmodellen</p>
                    
                    <p>Belevingswereld van de leerlingen vergroten</p>
                <!-- <a href="" class="hero-btn red-btn">Steun ons</a> -->
            </div>
            <div class="about-col image-col-right">
                <img src="../assets/images/sarah-voor-klas.jpeg" id="sarah-in-class-img">
            </div>
    </div>
  </section>

</template>

<script>
export default {
name: "GoalsSection"
}
</script>

<style scoped>
.goals {
    width: 80%;
    margin: auto;
    padding: 20px 0 50px;
}
.about-col {
    flex-basis: 48%;
    padding: 30px 2px;
}
.row .image-col-right{
  display: flex;
  justify-content: center;
}
.about-col img {
    width: 100%;
    max-width: 600px;
}
.about-col h1 {
    padding-top: 0;
}
.about-col p {
    padding: 15px 0 0px;
}

#sarah-in-class-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.row .image-col-right{
  display: flex;
  justify-content: center;
}

@media(max-width: 978px) {
    .goals {
        padding: 0 0 41px;       /* t lr b */
    }
    .about-col {
        flex-basis: 48%;
        padding: 10px 2px;
    }
    .row {
        flex-direction: column;
        margin-top: 23px;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    h3 {
        font-size: 16px;
    }

    .about-col img {
        max-width: 100%;
    }

    .about-col p {
        padding: 7px 0px 0px;
    }

    #sarah-in-class-img {
        height: 200px;
    }

}
</style>